<template>
  <section class="download" id="download">
    <div class="solid-color">
      <div class="container">
        <router-link v-if="!isHome" to="/">Home</router-link>
        <router-link to="/aboutprivacy">Privacy Policy</router-link>
        <router-link to="/aboutterms">Terms of Use </router-link>
        <a href="mailto:support11@magicface.com">Get Support</a>
        <!-- END ROW -->
      </div>
      <!-- /END CONTAINER -->
    </div>
    <!-- /END SOLID COLOR -->
  </section>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.name === "Home";
    }
  }
};
</script>
