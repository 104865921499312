<template>
  <header class="header" id="home">
    <!-- SOLID COLOR -->
    <div class="solid-color">
      <!-- To make header full screen. Use .full-screen class with solid-color. Example: <div class="solid-color full-screen">  -->
      <!-- STICKY NAVIGATION -->
      <div class="logo-title" style="text-align: left; padding: 36px">
        <router-link
          v-show="!isMobile"
          style="
            color: #fff;
            list-style: none;
            font-weight: 500;
            font-size: 32px;
          "
          to="/"
          >Photo Enhancer</router-link
        >
      </div>
      <!-- /END STICKY NAVIGATION -->
      <!-- CONTAINER -->
      <div class="container">
        <!-- /END ONLY LOGO ON HEADER -->
        <div class="row home-contents container-content">
          <div class="col-md-7 col-sm-7 hidden-xs">
            <!-- PHONE IMAGE WILL BE HIDDEN IN TABLET PORTRAIT AND MOBILE-->
            <div
              class="phone-image wow fadeInLeft animated"
              data-wow-offset="24"
              data-wow-duration="1.5s"
            >
              <img
                src="@/assets/imgs/1.png"
                class="img-responsive"
                alt=""
                width="450"
                height="600"
              />
            </div>
          </div>
          <div class="col-md-5 col-sm-5">
            <!-- HEADING AND BUTTONS -->
            <div class="intro-section">
              <!-- WELCOM MESSAGE -->
              <h1
                class="intro wow fadeInRight animated"
                data-wow-offset="24"
                data-wow-duration="1s"
              >
                AI Photo Enhancer
              </h1>
              <h5
                class="wow fadeInUp animated"
                data-wow-offset="24"
                data-wow-duration="1s"
                data-wow-delay="0.25s"
              >
                Turn your old, blurry photos into gorgeous, clear HD in just ONE
                TAP.
              </h5>
              <!-- BUTTON -->
              <!-- <a
                href="https://apps.apple.com/us/app/done/id1103961876"
                onclick="return clickedAppleDownload();"
              >
                <button
                  class="
                    buttons
                    btn btn-default btn-lg
                    standard-button
                    wow
                    fadeInUp
                    animated
                  "
                  data-wow-offset="24"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                  id="download-button"
                >
                  <i class="icon-app-store"></i>DOWNLOAD
                </button>
              </a> -->
              <!-- /END BUTTONS -->
            </div>
            <!-- /END HEADNING AND BUTTONS -->
          </div>
          <div
            class="hidden-sm hidden-md hidden-lg"
            style="padding-bottom: 48px"
          >
            <!-- PHONE IMAGE WILL BE HIDDEN IN TABLET PORTRAIT AND MOBILE-->
            <div class="phone-image">
              <img
                src="@/assets/imgs/1.png"
                class="img-responsive"
                alt=""
                width="0"
                height="0"
              />
            </div>
          </div>
        </div>
        <!-- /END ROW -->
      </div>
      <!-- /END CONTAINER -->
    </div>
    <!-- /END SOLID COLOR BG -->
  </header>
</template>

<script>
export default {
  data() {
    return {
      isMobile: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.isMobile = document.documentElement.clientWidth <= 550;
    });
  }
};
</script>
