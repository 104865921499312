<template>
  <section>
    <Header />
    <Featrues />
    <Workout />
    <Trends />
    <Download />
    <Footer />
  </section>
</template>

<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header";
import Featrues from "@/components/Featrues";
import Workout from "@/components/Workout";
import Trends from "@/components/Trends";
import Download from "@/components/Download";

export default {
  name: "Home",
  components: {
    Header,
    Featrues,
    Workout,
    Trends,
    Download,
    Footer
  },
  data() {
    return {
      animated: "",
      fixed: ""
    };
  },
  methods: {},
  mounted() {}
};
</script>

<style scoped></style>
