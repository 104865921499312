<template>
  <footer>
    <section class="container">
      <!-- LOGO -->

      <!-- SOCIAL ICONS -->
      <!-- <ul class="social-icons">
        <li>
          <a href="mailto:support+done@reflectly.app" target="_blank"
            ><i class="fa fa-envelope"></i
          ></a>
        </li>
        <li>
          <a href="https://www.instagram.com/the_done_app" target="_blank"
            ><i class="fa fa-instagram"></i
          ></a>
        </li>
      </ul> -->
      <!-- COPYRIGHT TEXT -->
      <p class="copyright">©{{ yearTime }} Photo Enhancer App</p>
    </section>
    <!-- /END CONTAINER -->
  </footer>
</template>

<script>
export default {
  data() {
    return {
      yearTime: new Date().getFullYear()
    };
  }
};
</script>
