<template>
  <section>
    <h2 class="page-title">TERMS OF USE</h2>
    <TermsContent></TermsContent>
    <Download />
  </section>
</template>
<script>
// import Footer from "@/components/Footer/Footer";

import TermsContent from "@/components/PrivacyTerms/TermsContent";
import Download from "@/components/Download";

export default {
  components: {
    TermsContent,
    Download
    // Footer
  }
};
</script>
<style scoped>
.page-title {
  font-size: 32px;
  text-align: center;
  padding: 64px 0 32px;
}
</style>
