<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import DefaultParams from "@/utils/defalut_params.js";
export default {
  created() {
    DefaultParams.init(this.$route.query);
  }
};
</script>

<style scoped>
#app {
  height: 100%;
}
</style>
